import Chef from "./chefAbi";
import {SwapPlatforms} from "@/config/swapPlatforms";
import {getSynapsePoolInfo} from "@/modules/vfat/synapse_helpers";
import {lookUpTokenPrices} from "@/modules/vfat/ethers_helper";

export const config = {
    name: "Synapse",
    url: "https://synapseprotocol.com/",
    chef: {
        address: "0x7875Af1a6878bdA1C129a4e2356A3fD040418Be5",
        rewardTokenTicker: "SYN",
        abi: Chef,
        rewardTokenFunction: "SYNAPSE",
        pendingRewardsFunction: "pendingSynapse",
        helpers: {
            getPoolInfo: getSynapsePoolInfo,
            lookUpTokenPrices: lookUpTokenPrices
        }
    },
    swap: SwapPlatforms.OneInch
}

export default config;
